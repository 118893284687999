import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Nav,
  Button,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import io from "socket.io-client";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { apiGet } from "../utils/apiFetch";
import useWindowDimensions from "../pages/SportAnalytics/getWindowSize";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaArrowRightLong } from "react-icons/fa6";
import { CiSquareMinus, CiSquarePlus, CiSquareRemove } from "react-icons/ci";
import {
  BrowserView,
} from "react-device-detect";
const Header = () => {
  const [socket, setSocket] = useState(null);
  const { register, handleSubmit } = useForm({ mode: "onChange" });
  const location = useLocation();
  const parmas = useParams();
  const navigate = useNavigate();
  const current_url = location.pathname.split("/")[1];
  let {
    user,
    logoutUser,
    user_coins,
    setUserCoins,
    getCoins,
    isRefereshLoader,
    setRefereshLoader,
    setNewSideBar,
    setHeightHeader,
  } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormSubmit = (form_data = {}) => {
    document.documentElement.style.setProperty("--primary", "#b36c3d");
  };
  const [checkRefresh, setCheckRefresh] = useState(true);

  const getSocket = async () => {
    setCheckRefresh(false);
    if (!isEmpty(socket)) {
      socket.disconnect();
    }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL}?token=${
        user?._id ? user?._id : 112233
      }`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      setUserCoins(message.results.totalCoins);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    setSocket(newSocket);
    newSocket.emit("getCoins", { user_id: user?._id });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("disconnect", function () {
      setCheckRefresh(true);
    });
    return () => newSocket.close();
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      if (!isEmpty(socket)) {
        socket.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });

  const [expanded, setExpanded] = useState(false);
  const [check, setCheck] = useState(
    parmas?.id && parmas.user_type ? true : false
  );
  const { width } = useWindowDimensions();
  const [data, setData] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const getData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.seriesList);
    if (response_users?.success) {
      if (response_users?.results?.length > 0) {
        response_users?.results?.map((res) => {
          return { ...res, expand: false };
        });
        setData({
          cricket: response_users?.results?.filter((res) => {
            return res?.gameType == "cricket";
          }),
          tennis: response_users?.results?.filter((res) => {
            return res?.gameType == "tennis";
          }),
          soccer: response_users?.results?.filter((res) => {
            return res?.gameType == "soccer";
          }),
        });
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  const ref = useRef(null);
  useEffect(() => {
    setHeightHeader(ref.current.clientHeight);
  });
  return (
    <div>
      <header ref={ref}>
        <Helmet>
        <meta name="viewport" content="width=1350px,initial-scale=1"/>
        </Helmet>
        <div className="top-header">
          <Container fluid>
            <Row className="align-items-center">
              <Col sm={4}>
                <div className="logo">
                  <a href="/" className="d-inline-block">
                  <img src="./assets/images/logo-bazz247.svg" alt="" style={{maxWidth:`130px`}}/>
                  </a>
                </div>
              </Col>
              {!isEmpty(user) ? (
                <Col sm={8}>
                  <div className="text-sm-end text-center top-header-owner">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <span>{user?.userType=="sub_owner" ?"owner":user?.userType || ""}</span>{" "}
                        <strong>{user?.username || ""}</strong>
                      </li>
                      {isRefereshLoader ? (
                        <li>
                          <p className="loading-bar" id="menuRefreshLoading">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </p>
                        </li>
                      ) : (
                        <li>
                          <a href="#" className="text-decoration-none">
                            <span>Main</span>{" "}
                            <strong>
                            USD ($) {helpers.currencyFormat(user_coins)}
                            </strong>
                          </a>
                          <a
                            href="#"
                            className="btn"
                            onClick={() => getCoins()}
                          >
                            <span>
                              <i className="fas fa-redo-alt"></i>
                            </span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>

        <div className="main-header">
          <Container  fluid>
          
            <div>
              <Navbar expand="xl" expanded={expanded}>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
            {/* <BrowserView>
                  <div style={{marginRight:"13px"}} className="d-flex justify-content-center align-items-center">
                    <GiHamburgerMenu
                      onClick={() =>
                        setNewSideBar((prev) => {
                          return !prev;
                        })
                      }
                      size={22}
                    
                      cursor={"pointer"}
                    />
                  </div>
              </BrowserView> */}
                    <Nav.Link
                      as={NavLink}
                      to="/"
                      onClick={() => setExpanded(false)}
                      className={
                        current_url === "" ||
                        current_url === "account-summary" ||
                        current_url === "betting-history" ||
                        current_url === "activity-log" ||
                        current_url === "betting-profit-loss" ||
                        current_url === "transaction-history" ||
                        current_url === "transaction-history-2" ||
                        current_url === parmas?.id
                          ? "active1"
                          : ""
                      }
                    >
                      {" "}
                      Downline List
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/my-account-summary"
                      className={
                        current_url === "my-account-summary" ||
                        current_url === "my-account-statement" ||
                        current_url === "my-profile" ||
                        current_url === "my-activity-log"
                          ? "active1"
                          : ""
                      }
                    >
                      My Account
                    </Nav.Link>

                    <NavDropdown title="My Report" id="basic-nav-dropdown">
                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitByDownline"
                    >
                      {" "}
                      Profit/Loss Report by Downline
                    </NavDropdown.Item> */}
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitDownline"
                    >
                      {" "}
                      Profit/Loss by Downline
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitMarket"
                    >
                      Profit/Loss Report by Market
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitPlayer"
                    >
                      Profit/Loss Report by Player
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/Adownlinesportspl"
                    >
                      Profit/Loss Sports Wise
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/ACdownlinesportspl"
                    >
                      All Casino Profit/Loss
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitCasino"
                    >
                      Casino Profit/Loss Report by Date
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/ACasinoprofitAndLossDownlineNew"
                    >
                      Casino P/L Downline Monthly
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/AprofitCasino"
                    >
                      International Casino Profit/Loss Report by Date
                    </NavDropdown.Item> */}

                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/ICasinoprofitAndLossDownlineNew"
                    >
                      International Casino P/L Downline Monthly
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/report/awc"
                    >
                      {" "}
                      Profit/Loss AWC Casino Bets
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/report/aura"
                    >
                      {" "}
                      Profit/Loss AURA Casino Bets
                    </NavDropdown.Item> */}
                    <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/report/egt"
                    >
                      {" "}
                      Profit/Loss EGT Casino Bets
                    </NavDropdown.Item>

                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/loginLogoutReport"
                    >
                      {" "}
                      Login Logout Report
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item
                      onClick={() => setExpanded(false)}
                      as={NavLink}
                      to="/recentlyCreatedAccount"
                    >
                      {" "}
                      Recently Created Account
                    </NavDropdown.Item> */}
                  </NavDropdown>

                    <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/Betlist"
                      className={current_url === "Betlist" ? "active1" : ""}
                    >
                      BetList
                    </Nav.Link>

                    <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/BetListLive"
                      className={current_url === "BetListLive" ? "active1" : ""}
                    >
                      BetListLive
                    </Nav.Link>

                    <Nav.Link
                      as={NavLink}
                      to="/RiskManagement"
                      onClick={() => setExpanded(false)}
                      className={
                        current_url === "RiskManagement" ? "active1" : ""
                      }
                    >
                      Risk Management
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/banking"
                      className={current_url === "banking" ? "active1" : ""}
                    >
                      Banking
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/block-market"
                      className={
                        current_url === "block-market" ? "active1" : ""
                      }
                    >
                      Block Market
                    </Nav.Link>
                    <Nav.Link
                    as={NavLink}
                    onClick={() => setExpanded(false)}
                    to="/events"
                    className={current_url === "events" ? "active1" : ""}
                  >
                    MM
                  </Nav.Link>
                    {/* {user?.userType === 'owner' && user?.username === 'superjohndoe' &&
                    // user.userType === "owner" ||
                    //   user.userType === "sub_owner" ||
                    //   user.userType === "super_admin"

                    (
                      <Nav.Link
                        as={NavLink}
                        onClick={() => setExpanded(false)}
                        to="/block-market"
                        className={
                          current_url === "block-market" ? "active1" : ""
                        }
                      >
                        Block Market
                      </Nav.Link>
                    )} */}
                    {user.userType === "owner" && (
                      <Nav.Link
                        as={NavLink}
                        onClick={() => setExpanded(false)}
                        to="/sport-setting"
                        className={
                          current_url === "sport-setting" ? "active1" : ""
                        }
                      >
                        Sport Setting
                      </Nav.Link>
                    )}
                    <Nav.Link
                      as={NavLink}
                      to="/general-setting"
                      onClick={() => setExpanded(false)}
                      className={
                        current_url === "general-setting" ||
                        current_url === "active-match" ||
                        current_url === "in-active-match" ||
                        current_url === "manage-links" ||
                        current_url === "WebsiteSetting"
                          ? "active1"
                          : ""
                      }
                    >
                      Admin Setting
                    </Nav.Link>

                    <Nav.Link
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      to="/result"
                      className={current_url === "result" ? "active1" : ""}
                    >
                      Result
                    </Nav.Link>

                    {user.userType === "owner" ||
                    user.userType === "sub_owner" ? (
                      <>
                        <Nav.Link
                          as={NavLink}
                          onClick={() => setExpanded(false)}
                          to="/add-match"
                          className={
                            current_url === "add-match" ? "active1" : ""
                          }
                        >
                          Add Match
                        </Nav.Link>
                      </>
                    ) : (
                      ""
                    )}
                    {user.userType == "owner" ||
                    user.userType == "sub_owner" ||
                    user.userType == "super_admin" ? (
                      <Nav.Link
                        as={NavLink}
                        onClick={() => setExpanded(false)}
                        to="/banner-list"
                        className={
                          current_url === "banner-list" ? "active1" : ""
                        }
                      >
                        Banner
                      </Nav.Link>
                    ) : (
                      ""
                    )}

                    {/* {width < 1200 && (
                      <>
                        <NavDropdown
                          title="Cricket"
                          className="dropdown-outer-new"
                          id="basic-nav-dropdown"
                        >
                          <div className="accordion-outer-item">
                            {data?.cricket?.map((res) => {
                              return (
                                <>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setData((prev) => {
                                        return {
                                          ...prev,
                                          cricket: prev?.cricket?.map(
                                            (item) => {
                                              if (item?._id == res?._id) {
                                                return {
                                                  ...item,
                                                  expand: !res?.expand,
                                                };
                                              } else {
                                                return item;
                                              }
                                            }
                                          ),
                                        };
                                      });
                                    }}
                                  >
                                    {res?.expand ? (
                                      <CiSquareMinus
                                        cursor={"pointer"}
                                        size={16}
                                      />
                                    ) : (
                                      <CiSquarePlus
                                        cursor={"pointer"}
                                        size={16}
                                      />
                                    )}
                                    <span>{res?.seriesName}</span>
                                  </div>
                                  {res?.expand && (
                                    <div className="accordion-outer-item-inner">
                                      {res?.matchList?.map((item) => {
                                        return (
                                          <div
                                            onClick={() => {
                                              navigate(
                                                `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                              );
                                              setExpanded(false);
                                            }}
                                          >
                                            <CiSquareRemove
                                              className="accordion-outer-item-inner-icon"
                                              cursor={"pointer"}
                                              size={16}
                                            />
                                            <span>{item?.eventName}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </NavDropdown>
                        <NavDropdown
                          title="Soccer"
                          className="dropdown-outer-new"
                          id="basic-nav-dropdown"
                        >
                          <div className="accordion-outer-item">
                            {data?.soccer?.map((res) => {
                              return (
                                <>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setData((prev) => {
                                        return {
                                          ...prev,
                                          soccer: prev?.soccer?.map((item) => {
                                            if (item?._id == res?._id) {
                                              return {
                                                ...item,
                                                expand: !res?.expand,
                                              };
                                            } else {
                                              return item;
                                            }
                                          }),
                                        };
                                      });
                                    }}
                                  >
                                    {res?.expand ? (
                                      <CiSquareMinus
                                        cursor={"pointer"}
                                        size={16}
                                      />
                                    ) : (
                                      <CiSquarePlus
                                        cursor={"pointer"}
                                        size={16}
                                      />
                                    )}
                                    <span>{res?.seriesName}</span>
                                  </div>
                                  {res?.expand && (
                                    <div className="accordion-outer-item-inner">
                                      {res?.matchList?.map((item) => {
                                        return (
                                          <div
                                            onClick={() => {
                                              navigate(
                                                `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                              );
                                              setExpanded(false);
                                            }}
                                          >
                                            <CiSquareRemove
                                              className="accordion-outer-item-inner-icon"
                                              cursor={"pointer"}
                                              size={16}
                                            />
                                            <span>{item?.eventName}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </NavDropdown>
                        <NavDropdown
                          title="Tennis"
                          className="dropdown-outer-new"
                          id="basic-nav-dropdown"
                        >
                          <div className="accordion-outer-item">
                            {data?.tennis?.map((res) => {
                              return (
                                <>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setData((prev) => {
                                        return {
                                          ...prev,
                                          tennis: prev?.tennis?.map((item) => {
                                            if (item?._id == res?._id) {
                                              return {
                                                ...item,
                                                expand: !res?.expand,
                                              };
                                            } else {
                                              return item;
                                            }
                                          }),
                                        };
                                      });
                                    }}
                                  >
                                    {res?.expand ? (
                                      <CiSquareMinus
                                        cursor={"pointer"}
                                        size={16}
                                      />
                                    ) : (
                                      <CiSquarePlus
                                        cursor={"pointer"}
                                        size={16}
                                      />
                                    )}
                                    <span>{res?.seriesName}</span>
                                  </div>
                                  {res?.expand && (
                                    <div className="accordion-outer-item-inner">
                                      {res?.matchList?.map((item) => {
                                        return (
                                          <div
                                            onClick={() => {
                                              navigate(
                                                `/sportanalysis/${item?.eventId}/${item?.marketId}`
                                              );
                                              setExpanded(false);
                                            }}
                                          >
                                            <CiSquareRemove
                                              className="accordion-outer-item-inner-icon"
                                              cursor={"pointer"}
                                              size={16}
                                            />
                                            <span>{item?.eventName}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </NavDropdown>
                      </>
                    )} */}
                  </Nav>
                </Navbar.Collapse>

                <div className="main-header-time-zone">
                  <ul className="list-unstyled  mb-0">
                    <li>
                      Time Zone : <span>GMT+5:30</span>
                    </li>
                    <li>
                      {isEmpty(user) ? (
                        <Link to="/login">
                          Login{" "}
                          <i className="fa-solid fa-right-from-bracket"></i>
                        </Link>
                      ) : (
                        <a
                          style={{ cursor: "pointer" }}
                          as={NavLink}
                          to="#"
                          onClick={logoutUser}
                        >
                          Logout{" "}
                          <i className="fa-solid fa-right-from-bracket"></i>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </Navbar>
            </div>
          </Container>
        </div>
      </header>

      {/* <Button
        variant="primary"
        onClick={handleShow}
        className="color-picker-btn"
      >
        <i className="fa-solid fa-gear"></i>
      </Button> */}

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Change Your Theme</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-center">
                <Form.Label for="primary" className="form-label">
                  Primary Color
                </Form.Label>
                <Form.Control
                  type="color"
                  className="form-control-color m-auto"
                  id="primary"
                  value="#3db39e"
                  title="Choose your color"
                  {...register("primary_color", {
                    required: "Please select color",
                  })}
                />
              </div>

              <div className="text-center">
                <Form.Label for="secondary" className="form-label">
                  Secondary Color
                </Form.Label>
                <Form.Control
                  type="color"
                  className="form-control-color m-auto"
                  id="secondary"
                  value="#060316"
                  title="Choose your color"
                  {...register("secondary_color", {
                    required: "Please select color",
                  })}
                />
              </div>
            </div>
            <button className="btn" type="submit">
              Save
            </button>
            <button className="btn" type="button">
              Reset
            </button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Header;
