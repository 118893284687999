import { startCase } from "lodash";
import React from "react";

const SportRight = ({ data, setAllBet,setPl }) => {
  return (
    <div
      class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-5 sport-anaylsis-inner"
      id="main-analysis-st"
    >
      {/* <div class="desktop-tv">
                      <div class="center-box libtnonoff">
                        <p>
                          <span>Live TV</span>
                          <div class="custom-control custom-switch float-right">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customSwitches"
                            ></label>
                          </div>
                        </p>
                      </div>
                    </div> */}
      <div class="main-analysis mb-2 ">
        <div class="">
          <div class="top">
            <div class="w-100">
              <div class="ifc">
                <span class="count">
                  Odds{" "}
                  <span class="pl-1 badge two">
                    {data?.BetFairBet?.matched?.length || 0}
                  </span>
                </span>
                <span class="count pl-1 pr-1">
                  BM{" "}
                  <span class="pl-1 badge two">
                    {" "}
                    {data?.BookmakerBet?.length || 0}
                  </span>
                </span>
                <span class="count pl-1">
                  Fancy{" "}
                  <span class="pl-1 badge two">
                    {" "}
                    {data?.FancyBet?.length || 0}
                  </span>
                </span>
                {/* <a
                  class="matched-btn ml-2 text-white"
                  data-toggle="modal"
                  data-target="#mystatement"
                >
                  Reset <i class="fa fa-filter ml-1" aria-hidden="true"></i>
                </a> */}
              </div>
              <a
                class="matched-btn float-right text-white"
                data-toggle="modal"
                onClick={() =>
                  setAllBet(true)
                }
                data-target="#mystatement"
              >
                All Bets <i class="fa fa-list ml-1" aria-hidden="true"></i>
              </a>
              <a onClick={() => setPl(true)}  class="matched-btn float-right text-white mr-1">
                P&amp;L <i class="fa fa-line-chart ml-1" aria-hidden="true"></i>
              </a>
            </div>
          </div>

          <div class="table-responsive  max-hight">
            <table class="w-100 table-sm font-12 text-black whitespace">
              <thead class="">
                <tr class="bg-dark text-white bg-dark1">
                  <td>UserName</td>
                  <td>Market</td>
                  <td>Runner</td>
                  <td>Rate</td>
                  <td>Amount</td>
                </tr>
              </thead>
              {data?.allBet?.length > 0 ? (
                <tbody>
                  {data?.allBet?.slice(0, 25)?.map((res) => {
                    return (
                      <tr
                        bgcolor={
                          res?.betType == "back" || res?.type == "Yes"
                            ? "#72BBEF"
                            : "#FAA9BA"
                        }
                      >
                        <td>{res?.clientName}</td>
                        <td>
                          {startCase(res?.betFaireType) || res?.fancyName}
                        </td>
                        <td>
                          {res?.teamName || `${res?.bhav} ${res?.betRun > 0 ? '/' : ''} ${res?.betRun > 0 ? res?.betRun : ''}`}
                        </td>
                        <td class="fbold">{res?.bhav}</td>
                        <td class="fbold">{res?.amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6}>No Record Found</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SportRight;
