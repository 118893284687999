import React from "react";

const PremiumSport = ({ data, handelPremiumFancy }) => {
  return data?.map((res) => {
    return (
      <div class="main-analysis mb-2">
        <div class="top">
          <div class="toptitle d-inline">{res?.marketName}</div>
          {/* <div>
            <span class="bk-btn">BOOK</span>
          </div>
          <div>
            <span class="bk-btn">
              BL <i class="fa fa-lock" aria-hidden="true"></i>
            </span>
          </div>
          <div>
            <span class="bk-btn">BetPlace</span>
          </div> */}
          <div class="min-max d-inline ">
            <span>
              <span class="desktop-minmax">
                Min: {res?.min} | Max: {res?.max}
              </span>
              {/* <span
                class="f-right"
                style={{ paddingLeft: "5px", cursor: "pointer" }}
              >
                {res?.expand ? (
                  <i
                    onClick={() => handelPremiumFancy(res?.id)}
                    class="fa fa-minus"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    onClick={() => handelPremiumFancy(res?.id)}
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                )}
              </span> */}
            </span>
          </div>
          {/* <div>
            <span class="bk-btn">0</span>
          </div> */}
        </div>
        <div class="table-responsive analysis-running-market bookmakerbs">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 p-right-left">
                <span>
                  <span class="mobile-minmax-winnerodds ">
                    Min: 100.00 | Max: 100000.00
                  </span>
                </span>
              </div>
            </div>
            {res?.sportsBookSelection?.length > 0 && (
              <div class="row">
                {res?.sportsBookSelection?.map((item) => {
                  return (
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 border-top1 p-right-left">
                      <div class="d-inline-flex w-100 lottery-p-2 odds">
                        <div class="d-inline-block lottery-w75 lottery-inplay v-align-top p-relative">
                          <div class='"d-inline-block'>
                            <span class="in-play-title in-play-title-winnerodds">
                              {item?.selectionName}
                            </span>
                            {Math.abs(item?.position) > 0 && (
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  color: item?.position > 0 ? "green" : "red",
                                }}
                              >
                                {(Math.abs(item?.position))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="d-inline-block lottery-w25 lottery-inplay btn-back ">
                          <div class="">
                            <a
                              class="btn-back border-bottom1 w-100 text-center d-block font-weight-bold "
                              side="Back"
                            >
                              <div>{item?.odds}</div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 border-top1 p-right-left">
                <div class="d-inline-flex w-100 lottery-p-2 odds">
                  <div class="d-inline-block lottery-w75 lottery-inplay v-align-top p-relative">
                    <div class='"d-inline-block'>
                      <span class="in-play-title in-play-title-winnerodds">
                        EVEN
                      </span>
                    </div>
                    <div class="d-inline-block runnerid float-right p-absolute winner-right4 "></div>
                  </div>
                  <div class="d-inline-block lottery-w25 lottery-inplay btn-back ">
                    <div class="">
                      <a
                        class="btn-back border-bottom1 w-100 text-center d-block font-weight-bold "
                        side="Back"
                      >
                        <div>1.98</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });
};

export default PremiumSport;
