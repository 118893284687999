import { isEmpty } from "lodash";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import styles from "../assets/newCss/sky.css";
import { Helmet } from "react-helmet";
import Aboutus from "../components/staticContent/Aboutus";
import GeneralTerms from "../components/staticContent/GeneralTerms";
import Kyc from "../components/staticContent/Kyc";
import PrivacyPolicy from "../components/staticContent/PrivacyPolicy";
import RulesRegulation from "../components/staticContent/RulesRegulation";
import ResponsibleGaming from "../components/staticContent/ResponsibleGaming";
function LoginMobile(props) {
  let {
    loginUser,
    isLoged,
    user,
    open,
    setOpen,
    content,
    slug,
    setSlug,
    handleContentToggle,
  } = useContext(AuthProvider);

  const navigate = useNavigate();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    unregister,
    reset: reset2,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });
  const [getValidateCode, setValidateCode] = useState("");
  const [data, setData] = useState({});
  const onSubmit2 = (data) => {
    if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
    }
  };
   const canvasRef = useRef(null);
  // const changeCode = () => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   context.font = "italic bold 150px serif";
  //   const code = Math.floor(1000 + Math.random() * 9000);
  //   context.clearRect(0, 0, canvas.width, canvas.height);
  //   context.fillText(code,50, 140,200);
  //   setValidateCode(code);
  // };
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "italic bold 150px serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130,300);
    setValidateCode(code);
  };
  const getSetting = async (event) => {
    try {
      const { status, data: response_users } = await apiGet(apiPath.getSetting);
      if (status === 200) {
        if (response_users.success) {
          setData(response_users.results);
        }
      }
    } catch (err) { }
  };
  useEffect(() => {
    changeCode();
    getSetting();
  }, []);
  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user]);

  return (
    <div className="newLogin">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        />
      </Helmet>

      <div className="login_main" style={{height: `100vh`}}>
        <header className="login-head">
          {/* <Link to="/" className="close"></Link> */}
          {/* <h1></h1> */}
          <div id="powerWrap" className="betfair-wrap">
            <p>Powered by</p>
            <span>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/betfair_black.png"
                }
                alt=""
                draggable="false"
              />
            </span>
          </div>
        </header>
        <Form onSubmit={handleSubmit2(onSubmit2)}>
          <dl className="form-login">
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="loginNameErrorClass"
            >
              <Form.Control
                type="text"
                autocomplete="off"
                // id="loginName"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
                style={{padding:`5.66667vw 1.86667vw`}}
              />
              {errors2.username && errors2?.username?.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="passwordErrorClass"
            >
              <Form.Control
                type="password"
                autocomplete="off"
                // id="password"
                className={errors2.password ? "is-invalid" : ""}
                {...register2("password", {
                  required: "Please enter password",
                })}
                data-role="none"
                placeholder="Password"
                style={{padding:`5.66667vw 1.86667vw`}}
              />
              {errors2.password && errors2.password.message && (
                <label className="invalid-feedback text-left">
                  {errors2.password.message}
                </label>
              )}
            </Form.Group>
            <dd id="validCodeErrorClass" style={{ display: "block" }}>
              <input
                type="number"
                keyboardType="numeric"
                autocomplete="off"
                maxLength="4"
                className={errors2.validateCode ? "is-invalid" : ""}
                {...register2("validateCode", {
                  required: "Please enter validate code",
                  validate: {
                    validate: (value) =>
                      parseInt(value) === parseInt(getValidateCode) ||
                      "Invalid validate code",
                  },
                })}
                onChange={(e) => {
                  if (e.target.value.length == 4) {
                    e.target.blur();
                    unregister("validateCode", { keepValue: true });
                  }
                }}
                // onChange={(e) => {
                //   if (e.target.value.length <= 4) {
                //     setCode(false);
                //   } else {
                //     setCode(true);
                //   }
                // }}
                id="validCode"
                placeholder="Captcha"
                style={{padding: `2.66667vw 1.86667vw`}}
              // onClick={(e) => e.target.blur()}
              // onKeyDown={(e) => e.target.blur()}
              />
              {/* <img
                id="authenticateImage"
                src="assets/images/verifycode.jpg"
                alt=""
              /> */}
              <canvas
                ref={canvasRef}
                onClick={changeCode}
                className="inputcanvas"
                id="authenticateImage"
              />

              {errors2.validateCode && errors2.validateCode.message && (
                <label className="invalid-feedback text-left">
                  {errors2.validateCode.message}
                </label>
              )}
            </dd>
            {/* <dd>
              <input name="valid" type="hidden" id="valid" />
            </dd> */}
            <dd>
              <Button type="submit" className="btn-send w-100" id="loginBtn">
                Login
              </Button>
            </dd>
            {isLoged == "failed" && (
              <dd id="errorMsg" class="state">
                Login name or password is invalid! Please try again.
              </dd>
            )}
          </dl>
        </Form>

       


        
        
      </div>
    </div>
  );
}

export default LoginMobile;
