import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Col,
  Button,
  Row,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { Link, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Breadcrumbs from "./Users/Breadcrumbs";
import { isEmpty } from "lodash";
import helpers from "../utils/helpers";

const LifeTimePL = () => {
  const params = useParams();

  const [summaryData, setSummaryData] = useState("");
  const accountSummary = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.lifeTimePL + "?user_id=" + params?.id
    );
    if (status === 200) {
      if (response_users.success) {
        setSummaryData(response_users.results);
      }
    }
  };

  useEffect(() => {
    if (params?.id) {
      accountSummary();
    }
  }, [params?.id]);

  console.log(summaryData,'========')
  return (
    <div>
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <Breadcrumbs user_id={params?.id} />

          <div className="accout_cols_outer">
            <div className="left_side">
              <Sidebar />
            </div>
            <div className="right_side">
              <div className="inner-wrapper">
                <h2 className="common-heading">Balance Summary</h2>
                <div className="profile-tab">
                  <Row>
                    <Col lg={7} md={12}>
                      <Table>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              colSpan="4"
                              style={{
                                background:
                                  "linear-gradient(180deg, #2a3a43 27%, #1c282d 83%)",
                                color: "white",
                              }}
                              className="text-start"
                            >
                              About Balance
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-start" width="25%">
                              Total Deposit
                            </td>
                            <td
                              className="text-success"
                              style={{ fontWeight: "600" }}
                              colSpan="3"
                            >
                              {helpers.currencyFormat(summaryData?.totalDeposit) || 0.00}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start" width="25%">
                              Total withdraw
                            </td>
                            <td
                              className="text-danger"
                              style={{ fontWeight: "600" }}
                              colSpan="3"
                            >
                              -{helpers.currencyFormat(summaryData?.withdraw) || 0.00}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-start" width="25%">
                              Balance
                            </td>
                            <td
                              className={
                                isEmpty(summaryData?.balance)
                                  ? ""
                                  : summaryData?.balance > 0
                                  ? "text-success"
                                  : "text-danger"
                              }
                              style={{ fontWeight: "600" }}
                              colSpan="3"
                            >
                              {helpers.currencyFormat(summaryData?.balance) || 0.00}
                            </td>
                          </tr>

                          <tr>
                            <td className="text-start" width="25%">
                              Final PL
                            </td>
                            <td
                              className={
                                summaryData?.finalPL==""
                                  ? ""
                                  : summaryData?.finalPL > 0
                                  ? "text-success"
                                  : "text-danger"
                                
                              }
                              
                              style={{ fontWeight: "600" }}
                              colSpan="3"
                            >
                              {helpers.currencyFormat(summaryData?.finalPL) || 0.00}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default LifeTimePL;
