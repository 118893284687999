import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { useEffect } from "react";
import obj from "../utils/constants";
import { isEmpty } from "lodash";
import helpers from "../utils/helpers";
const ViewBetsRisk = () => {
  const params = useParams();
  // console.log(params, "para,s");
  const [data, setData] = useState([]);
  const [getBetType, setBetType] = useState("betfair");
  const [getType, setType] = useState("4");
  const [detail, setDetail] = useState({});
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    status: "active",
    eventId: "",
  });
  const getData = async (obj) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getBetslive,
      !isEmpty(obj) ? obj : filter
    );
    if (status === 200) {
      if (response_users.success) {
        setBetType(filter.betType);
        setType(filter.type);
        setData(response_users?.results);
      }
    }
  };

  useEffect(() => {
    setDetail(params);
    setFilter((prev) => {
      return {
        ...prev,
        eventId: params?.userId,
        betType:
          params?.type == "Match Odds"
            ? "betfair"
            : params?.type == "Fancy Bet"
            ? "fancy"
            : params?.type == "Book Maker"
            ? "bookmaker"
            : params?.type == "Toss"
            ? "toss"
            : "",
        type: params?.type == "Fancy Bet" ? 4 : params?.gameType,
        selectionId: params?.type == "Fancy Bet" ? params?.selectionId : "",
      };
    });
    getData({
      ...filter,
      eventId: params?.userId,
      betType:
        params?.type == "Match Odds"
          ? "betfair"
          : params?.type == "Fancy Bet"
          ? "fancy"
          : params?.type == "Book Maker"
          ? "bookmaker"
          : params?.type == "Toss"
          ? "toss"
          : "",
      type: params?.type == "Fancy Bet" ? 4 : params?.gameType,
      selectionId: params?.type == "Fancy Bet" ? params?.selectionId : "",
    });
  }, [params]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getData({
        ...filter,
        eventId: detail?.userId,
        betType:
          detail?.type == "Match Odds"
            ? "betfair"
            : detail?.type == "Fancy Bet"
            ? "fancy"
            : detail?.type == "Book Maker"
            ? "bookmaker"
            : detail?.type == "Toss"
            ? "toss"
            : "",
        type: detail?.type == "Fancy Bet" ? 4 : detail?.gameType,
        selectionId: detail?.type == "Fancy Bet" ? detail?.selectionId : "",
      });
    }, 10 * 1000);

    return () => clearInterval(intervalID);
  }, [detail]);
  return (
    <section className="main-inner-outer py-4">
      <section className="account-table">
        <div className="container-fluid">
          <div className="db-sec d-flex justify-content-between align-items-center mb-2">
            <h2 className="common-heading">
              {detail?.userType} ({detail?.type})
            </h2>
            <Button className="green-btn" onClick={() => window.close()}>
              X
            </Button>
          </div>
          <div className="responsive">
            <Table>
              <thead>
                <tr>
                  <th scope="col">PL ID</th>
                  <th scope="col"> Bet ID</th>
                  <th scope="col">Bet placed</th>
                  <th scope="col">IP Address </th>
                  <th scope="col">Market</th>
                  <th scope="col">Selection</th>
                  <th scope="col">Type</th>
                  {filter?.betType != "casino" && (
                    <th scope="col">Odds req.</th>
                  )}

                  <th scope="col">Stake </th>

                  {filter?.betType != "casino" && (
                    <>
                      <th scope="col">Liability</th>
                      <th scope="col"> Profit/Loss</th>
                    </>
                  )}
                </tr>
              </thead>
              {filter?.betType != "casino" && data && data?.length > 0
                ? data?.map((item) => {
                    if (item.isMatched || !isEmpty(item?.fancyName)) {
                      return (
                        <tr>
                          <td>{item?.clientName || "-"}</td>
                          <td>
                            {" "}
                            {getType === "3"
                              ? item?.casinoBetId
                              : filter?.betType === "fancy"
                              ? item?.sessionBetId
                              : item?.matchBetId || "-"}
                          </td>
                          <td> {helpers.dateFormat(item?.timeInserted)}</td>
                          <td>
                            {" "}
                            {(item?.ipAddress &&
                              item?.ipAddress.replace("::ffff:", "")) ||
                              "-"}
                          </td>
                          <td className="text-start">
                            {obj.betCheckObj[Number(item?.eventType)]}
                            <span className="angle_unicode">&#10148;</span>
                            <strong>
                              {getType === "3"
                                ? item?.casinoName
                                : item?.matchName}
                            </strong>
                            <span className="angle_unicode">&#10148;</span>
                            {filter?.betType === "betfair" && "Match Odds"}{" "}
                            {filter?.betType === "bookmaker" && "Book Maker"}
                            {filter?.betType === "fancy" && item?.fancyName}
                          </td>
                          <td>
                            {filter?.betType === "fancy"
                              ? item?.betRun + "/" + item?.bhav
                              : filter?.betType === "sportBook"
                              ? item?.runnerName
                              : filter?.betType === "casino"
                              ? item?.platformTxId
                              : item?.teamName}
                          </td>
                          <td> {item?.betType || item?.type} </td>
                          <td> {getType === "3" ? 0 : item?.bhav || "-"} </td>
                          <td> {item?.amount || "-"} </td>
                          <td> {item?.loseAmount || "-"} </td>
                          <td>
                            {getType === "3" ? (
                              Math.sign(item?.playerPL) === -1 ? (
                                <span className="text-danger">
                                  ({helpers.currencyFormat(item?.playerPL)})
                                </span>
                              ) : (
                                <span className="text-success">
                                  (
                                  {helpers.currencyFormat(
                                    Math.abs(item?.playerPL)
                                  )}
                                  )
                                </span>
                              )
                            ) : item?.betType == "back" ||
                              item?.type == "Yes" ? (
                              <span className="text-success">
                                {item?.profitAmount}
                              </span>
                            ) : (
                              <span className="text-danger">
                                -({item?.loseAmount})
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })
                : null}

              {filter?.betType == "casino" && data && data?.length > 0
                ? data?.map((item) => {
                    return (
                      <tr>
                        <td>{item?.clientName || "-"}</td>
                        <td> {item?.casinoBetId}</td>
                        <td> {helpers.dateFormat(item?.timeInserted)}</td>
                        <td>
                          {" "}
                          {(item?.ipAddress &&
                            item?.ipAddress.replace("::ffff:", "")) ||
                            "-"}
                        </td>
                        <td className="text-start">
                          {obj.betCheckObj[Number(getType)]}
                          <span className="angle_unicode">&#10148;</span>
                          <strong>
                            {getType === "3"
                              ? item?.casinoName
                              : item?.matchName}
                          </strong>
                          <span className="angle_unicode">&#10148;</span>
                          {getBetType === "betfair" && "Match Odds"}{" "}
                          {getBetType === "Bookmaker" && "Book Maker"}
                          {getBetType === "sportBook" && "Sport Book"}
                          {getBetType === "fancy" && item?.fancyName}{" "}
                          {getBetType === "casino" && item?.casinoType}{" "}
                        </td>
                        <td> {item?.platformTxId}</td>
                        <td> {item?.gameCode} </td>
                        <td> {item?.betAmount || "-"} </td>
                      </tr>
                    );
                  })
                : null}
              {isEmpty(data) && (
                <tbody>
                  <tr>
                    <td colSpan={12}>No Record Found</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ViewBetsRisk;
