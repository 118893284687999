import React, { useEffect, useState } from "react";
import { Table, Modal, Row, Col, Form } from "react-bootstrap";
import obj from "../../utils/constants";
import helpers from "../../utils/helpers";
import { isEmpty } from "lodash";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
// import { FiRefreshCw } from "react-icons/fi";
const ProfitLossSport = ({ open, onClose, detail, params }) => {
  const [data, setData] = useState([]);
  const [select, setSelect] = useState("all");
  const getBetList = async () => {
    const { status, data: response_users } = await apiGet(apiPath.eventsBets, {
      eventId: params?.eventId,
      status: "completed",
    });
    if (status === 200) {
      if (response_users.success) {
        setData({
          ...response_users?.results,
          allBet: [
            ...(response_users?.results?.BetFairBet?.matched || []),
            ...(response_users?.results?.BookmakerBet || []),
            ...(response_users?.results?.FancyBet || []),
            ...(response_users?.results?.SportBooksBet || []),
          ],
        });
      }
    }
  };

  useEffect(() => {
    getBetList();
  }, [params]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      getBetList();
    }, 15 * 1000);

    return () => clearInterval(intervalID);
  }, []);

  let Obj = {
    all: data?.allBet || [],
    betFair: data?.BetFairBet?.matched || [],
    bookmaker: data?.BookmakerBet || [],
    fancy: data?.FancyBet || [],
  };
console.log(Obj[select],'Obj')

  return (
    <Modal show={open} onHide={onClose} size="2xl" fullscreen={"2xl"}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="modal-title-status">
          Settled Markets P&L
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <div>
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <h6>
              {detail?.eventId} - {detail?.eventName}{" "}
              {/* <FiRefreshCw
                color="green"
                cursor={"pointer"}
                style={{ marginLeft: "5px" }}
                onClick={() => getBetList()}
              /> */}
            </h6>{" "}
            <h6>
              {detail?.eventId} - {detail?.seriesName}
            </h6>
          </div>
          <Row className="mb-3">
            <Col lg={3} md={3} sm={6}>
              <Form.Label>Select Market Type</Form.Label>
              <Form.Select
                onChange={(e) => setSelect(e.target.value)}
                value={select}
                aria-label="Default select example"
              >
                {" "}
                <option value={"all"}>Select Market Type</option>
                <option value={"betFair"}>BetFair</option>
                <option value={"bookmaker"}>Bookmaker</option>{" "}
                <option value={"fancy"}>Fancy</option>
              </Form.Select>
            </Col>
          </Row>{" "}
          <div
            style={{
              maxHeight: "70vh",
              overflow: "scroll",
              scrollbarWidth: "none",
            }}
            className="responsive"
          >
            <Table className="all-bets-dialog-tabel">
              <thead>
                <tr>
                  <th className="allbet-tr" scope="col">
                    Market
                  </th>
                  <th className="allbet-tr" scope="col">
                    Declare Date & Time
                  </th>
                  <th className="allbet-tr" scope="col">
                    Winner
                  </th>
                  {/* <th scope="col">Order Count</th> */}
                  <th className="allbet-tr" scope="col">
                    Total Amount
                  </th>
                  <th className="allbet-tr" scope="col">
                    P/L
                  </th>
                </tr>
              </thead>
              {Obj[select]?.length > 0 ? (
                Obj[select]?.map((item) => {
                  return (
                    <tr
                      bgcolor={
                        item?.betType == "back" || item?.type == "Yes"
                          ? "#72BBEF"
                          : "#FAA9BA"
                      }
                    >
                      {" "}
                      <td className="text-start">
                        {obj.betCheckObj[Number(item?.eventType)]}
                        <span
                          style={{ background: "transparent" }}
                          className="angle_unicode"
                        >
                          &#10148;
                        </span>
                        <strong style={{ background: "transparent" }}>
                          {item?.matchName}
                        </strong>
                        <span
                          style={{ background: "transparent" }}
                          className="angle_unicode"
                        >
                          &#10148;
                        </span>
                        {item?.betFaireType === "betfair" && "Match Odds"}
                        {item?.fancyName !== "" && item?.fancyName}
                      </td>
                      {/* <td> {item?.matchBetId || item?.sessionBetId}</td> */}
                      <td> {helpers.dateFormat(item?.timeInserted)}</td>
                      <td>
                        {item?.teamName || item?.betRun + "/" + item?.bhav}
                      </td>
                      <td> {item?.amount || "-"} </td>
                      <td>
                        {item?.betType == "back" || item?.type == "Yes" ? (
                          <span
                            style={{ background: "transparent" }}
                            className="text-success"
                          >
                            {item?.profitAmount}
                          </span>
                        ) : (
                          <span
                            style={{ background: "transparent" }}
                            className="text-danger"
                          >
                            -({item?.loseAmount})
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={12}>No Record Found</td>
                </tr>
              )}
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProfitLossSport;
